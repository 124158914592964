import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import React, { createContext, useContext } from 'react'

import { checkIsAuthenticated } from '../../../lib/auth'

const AuthContext = createContext(null)

/**
 * Provides authentication context from redux
 *
 * @param {boolean} isAuthenticated
 * @param {object} session
 * @param {function} dispatch
 */
const AuthProvider = ({
  isAuthenticated, session, dispatch, children
}) => (
  <AuthContext.Provider
    value={{
      isAuthenticated,
      session,
      dispatch
    }}
  >
    {children}
  </AuthContext.Provider>
)

AuthProvider.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  session: PropTypes.shape({
    token: PropTypes.string,
    decodedJwt: PropTypes.shape({
      sub: PropTypes.string,
      email: PropTypes.string,
      first_name: PropTypes.string
    })
  }),
  dispatch: PropTypes.func
}

/**
 * Consumes the authentication context to provide user information
 * and other authentication functions
 */
export const useAuth = () => {
  const ctx = useContext(AuthContext)

  if (ctx === null) throw new Error('useAuth() can only be used inside an <AuthProvider />')

  let user = null
  let token = null

  if (ctx.session && ctx.session.token) {
    token = ctx.session.token
    user = {
      id: ctx.session.decodedJwt.sub,
      email: ctx.session.decodedJwt.email,
      firstName: ctx.session.decodedJwt.first_name
    }
  }

  return {
    user,
    token,
    dispatch: ctx.dispatch,
    isAuthenticated: ctx.isAuthenticated
  }
}

export default compose(
  connect(
    state => ({
      isAuthenticated: checkIsAuthenticated(state),
      session: state.session
    }),
    dispatch => ({ dispatch })
  )
)(AuthProvider)
